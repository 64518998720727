<template>
  <div class="main-wrapper">
    <!-- <div class="header">
      <img
        class="logo"
        height="35%"
        :src="require('@/assets/imgs/logo.png')"
      >
    </div>
    <Lang @changeLang="changeLang" /> -->
    <div class="claim-wrapper">
      <div class="lang">
        <div class="lang-text">{{ $t('ebClaim.Language') }}</div>
        <div class="lang-select">
          <span
            class="lang-btn"
            :class="$store.state.base.lang == 'en' ? 'active' : ''"
            @click="changeLang('en')"
          ><img class="img" :src="require('@/assets/imgs/EN.png')">EN</span>
          <span
            class="lang-btn"
            :class="$store.state.base.lang == 'vi' ? 'active' : ''"
            @click="changeLang('vi')"
          ><img class="img" :src="require('@/assets/imgs/VN.png')">VN</span>
        </div>
      </div>

      <h1 class="">FUSE</h1>
      <div class="img-div"><img :src="img0" class="img0"></div>
      <p class="welcome-p">{{ $t('ebClaim.welcome') }}👋🏻</p>
      <p class="question-p">{{ $t('ebClaim.question') }}</p>
      <div class="guide-div">
        <span class="guide-div-text1">{{ $t('ebClaim.guideText') }}</span>
        <span class="guide-btn" @click="$router.push({name: 'GuidancePage'})">{{ $t('ebClaim.guideBtn') }}</span>
      </div>

      <div class="claim-start-div">
        <img :src="guide1" alt="">
        <div class="claim-start-desc">
          <p class="desc-title">{{ $t('ebClaim.SubmitClaim') }}</p>
          <p class="desc-sub">{{ $t('ebClaim.SubmitClaimSimple') }}</p>
        </div>
        <div class="claim-start-btn" @click="$router.push({name: 'EbSubmitClaim'})">{{ $t('ebClaim.start') }}</div>
      </div>

      <div class="claim-start-div">
        <img :src="guide2" alt="">
        <div class="claim-start-desc">
          <p class="desc-title">{{ $t('ebClaim.TrackingClaim') }}</p>
          <p class="desc-sub">{{ $t('ebClaim.TrackingClaimSimple') }}</p>
        </div>
        <div class="claim-start-btn" @click="$router.push({name: 'EbTrackClaim'})">{{ $t('ebClaim.start') }}</div>
      </div>
    </div>

  </div>

</template>

<script>
// import Lang from '@/views/inspectionCustomer/component/lang/index.vue'
import store from '@/store'
import img0 from '@/assets/imgs/portal0.png'
import guide1 from '@/assets/imgs/portal-guide1.png'
import guide2 from '@/assets/imgs/portal-guide2.png'
export default {
  // components: { Lang },
  props: {},
  data() {
    return {
      lang: this.$store.state.base.lang,
      img0, guide1, guide2
    }
  },
  methods: {
    changeLang(val) {
      // this.lang = val
      this.$i18n.locale = val
      store.dispatch('base/changeLang', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  padding: 15px 0;
  position: relative;
  overflow: scroll;
}
.lang-text {
  font-family: 'Montserrat';
}
.img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
h1 {
  text-align: center;
  margin-top: 67px;
  color: #E8413A;
  font-size: 16px;
  font-family: 'Montserrat-Bold';
}
.img-div {
  text-align: center;
}
.img0 {
  width: 186px;
  margin: 0 auto;
}
.welcome-p {
  text-align: center;
  margin-top: 40px;
  font-size: 12px;
  font-family: 'Montserrat';
}
.question-p {
  text-align: center;
  font-size: 14px;
  margin: 15px 0 15px;
  font-family: 'Montserrat-Bold';
}
.guide-div {
  display: flex;
  background: #f5f5f5;
  margin: 15px 20px 0;
  padding: 20px 20px;
  justify-content: space-between;
  border-radius: 35px;
  .guide-div-text1 {
    font-family: 'Montserrat';
  }
  .guide-btn {
    color: #E74039;
    font-family: 'Montserrat-Bold';
  }
}
.claim-start-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 20px;
  padding: 10px 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px #0000001A;
  .claim-start-desc {
    flex: 1;
  }
  .desc-title {
    font-family: 'Montserrat-Bold';
  }
  .desc-sub {
    font-family: 'Montserrat';
  }
  img {
    width: 32px;
    margin-right: 10px;
  }
  .claim-start-btn {
    width: 70px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    line-height: 28px;
    background: #f5f5f5;
    font-family: 'Montserrat-Bold';
  }
}
.header {
  padding: 0 20px;
  height: 80px;
  line-height: 75px;
  background-color: #D90109;
  font-size: 0;
  text-align: center;
}
.lang {
  margin: 0 15px;
  border: 1px solid #DCDEE0;
  border-radius: 10px;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  .lang-select {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: #f5f5f5;
    padding: 5px;
    .lang-btn {
      font-family: 'Montserrat-Bold';
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .active {
      background-color: #fff;
    }
  }
}
</style>
